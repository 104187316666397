import { Link } from "react-router-dom";

import React from "react";
import { PlusSquareFilled, PlusSquareTwoTone } from "@ant-design/icons";
import { ROUTER_ROUTE } from "routers/routers";
import { BREADCRUMB } from "types/enums/breadCrumb";
import { CatalogSVG, CourseSVG, MarkThePointSVG, UserSVG } from "assets/svg";
import { ROLE } from "types/enums/role";

const menus: any[] = [
  {
    label: BREADCRUMB.EXAM_LIST,
    link: ROUTER_ROUTE.EXAM,
    key: "khoaHoc",
    icon: <CourseSVG />,
    roles: [ROLE.ROLE_ADMIN],
  },
  {
    label: BREADCRUMB.GRADE_USER_LIST,
    key: "chamDiem",
    link: ROUTER_ROUTE.GRADE_USER_LIST,
    icon: <MarkThePointSVG />,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_CONSULTANT,
      ROLE.ROLE_MODERATOR,
      ROLE.ROLE_CONSULTANT_CTV,
    ],
  },
  {
    label: BREADCRUMB.USER,
    link: ROUTER_ROUTE.USER,
    key: "user",
    icon: <UserSVG />,
    roles: [ROLE.ROLE_ADMIN],
  },
  {
    label: BREADCRUMB.CATALOG,
    link: ROUTER_ROUTE.CATALOG,
    key: "/catalog",
    icon: <CatalogSVG />,
    roles: [ROLE.ROLE_ADMIN],
  },
];
const siderData: any[] = [];
const pushSiderData = (menus, siderData) => {
  menus.forEach((menuItem, index) => {
    siderData.push({
      key: menuItem.key,
      icon: menuItem.icon,
      label: menuItem.link ? (
        <Link className="link-menu" to={`${menuItem.link}`}>
          {menuItem.label}
        </Link>
      ) : (
        <div>{menuItem.label}</div>
      ),
      children: menuItem.subItems?.map((subItem, index) => {
        return {
          className: "sub-menu",
          key: subItem.key,
          icon: subItem.icon,
          label: (
            <Link className="link-submenu" to={`${subItem.link}`}>
              {subItem.label}
            </Link>
          ),
        };
      }),
    });
  });
};
pushSiderData(menus, siderData);
export { siderData, menus, pushSiderData };
