import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQuery, baseQueryWithReauth } from "types/api/base-query";

const { POST, PUT, DELETE } = API_METHOD;

export const USER_API_REDUCER_KEY = "userApi";

export const userApi = createApi({
  reducerPath: USER_API_REDUCER_KEY,
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    postUser: builder.mutation<any, any>({
      query: (data) => ({
        url: apiPath.POST_USER,
        method: POST,
        data,
      }),
      invalidatesTags: ["Post"],
    }),

    putUser: builder.mutation<any, any>({
      query: (data) => ({
        url: apiPath.PUT_USER,
        method: PUT,
        data,
      }),
      invalidatesTags: ["Post"],
    }),
    deleteUser: builder.mutation<any, any>({
      query: (id) => ({
        url: `${apiPath.DELETE_USER}?id=${id}`,
        method: DELETE,
      }),
      invalidatesTags: ["Post"],
    }),
    getListUserByParams: builder.query<any, any>({
      query: (data) => ({
        url: `${apiPath.GET_LIST_USER}${serialize(data)}`,
      }),
      transformErrorResponse: (response: any) => response.error,
      providesTags: ["Post"],
    }),
  }),
});

export const userApiReducer = userApi.reducer;

export const {
  usePostUserMutation,
  useGetListUserByParamsQuery,
  usePutUserMutation,
  useDeleteUserMutation,
} = userApi;
