const POST_USER = "/user/create";
const PUT_USER = "/user/update";
const GET_LIST_USER = "/user/paging_search";
const DELETE_USER = "/user/delete";
const CHANGE_TO_CTV = "/user/change_to_ctv";

export const user = {
  POST_USER,
  PUT_USER,
  GET_LIST_USER,
  DELETE_USER,
  CHANGE_TO_CTV,
};
